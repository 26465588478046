const metaDefaults = {
};

const opengraphDefaults = {
  image: null,
  type: 'website',
  site_name: 'FoodtruckSpotters.nl',
  locale: 'nl_NL',
};

export default function createMeta(route) {
  return {
    meta: Object.assign({ description: route.description }, metaDefaults, route.meta),
    opengraph: Object.assign({}, opengraphDefaults, route.opengraph),
  };
}
